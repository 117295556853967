@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@800&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --toastify-toast-width: 200px!important;
  --toastify-text-color-light: #fff!important;
  --toastify-text-color-dark: #fff!important;
  --toastify-color-light: #e74c3c!important;
  --toastify-color-dark: #e74c3c!important;
  --clr-present: var(--clr-yellow);
  --clr-correct: var(--clr-green);
  --clr-absent: var(--clr-med-grey);
  --clr-key-text: var(--clr-black);
  --clr-key-bkgnd: var(--clr-light-grey);
  --clr-tile-text: var(--clr-black);
  --clr-tile-bkgnd: var(--clr-white);
  --clr-tile-highlight: 2px solid var(--clr-med-grey);
  --clr-error: var(--clr-red);
  --clr-red: #e74c3c;
  --clr-white: #fff;
  --clr-black: #121212;
  --clr-green: #6aaa64;
  --clr-yellow: #c9b458;
  --clr-dkTheme: rgba(184, 196, 188, 1);
  --clr-light-grey: #c6c3c3;
  --clr-med-grey: #787c7e;
  --clr-dk-grey: #454545;
  --keys-width: 8%;
  --keys-height: 60px;
  --enterKey-width: 60px;
  --keypad-width: 350px;
}
:root {
  --clr-present: var(--clr-yellow);
  --clr-correct: var(--clr-green);
  --clr-absent: var(--clr-dk-grey);
  --clr-key-text: var(--clr-white);
  --clr-key-bkgnd: var(--clr-med-grey);
  --clr-tile-text: var(--clr-white);
  --clr-tile-bkgnd: var(--clr-white);
  --clr-tile-highlight: 2px solid var(--clr-med-grey);
  --background: var(--clr-black);
  --border-highlight: 2px solid var(--clr-dk-grey);
  --clr-white: #fff;
  --clr-black: #121212;
  --clr-red: #e74c3c;
  --clr-green: #6aaa64;
  --clr-yellow: #c9b458;
  --clr-light-grey: #bcbcbc;
  --clr-med-grey: #787c7e;
  --clr-dk-grey: #454545;
  --clr-error: var(--clr-red);
}

@media (prefers-color-scheme: dark){
  :root{
    --clr-present: var(--clr-yellow);
    --clr-correct: var(--clr-green);
    --clr-absent: var(--clr-dk-grey);
    --clr-key-text: var(--clr-white);
    --clr-key-bkgnd: var(--clr-med-grey);
    --clr-tile-text: var(--clr-white);
    --clr-tile-bkgnd: var(--clr-white);
    --clr-tile-highlight: 2px solid var(--clr-med-grey);
    --background: var(--clr-black);
    --border-highlight: 2px solid var(--clr-dk-grey);
    --clr-white: #fff;
    --clr-black: #121212;
    /* --clr-red: #ea730b; */
    --clr-red: #e74c3c;
    --clr-green: #6aaa64;
    --clr-yellow: #c9b458;
    --clr-light-grey: #bcbcbc;
    --clr-med-grey: #787c7e;
    --clr-dk-grey: #454545;
    --clr-error: var(--clr-red);
  }  
}

.light-theme-stripe {
  background-image: linear-gradient(
    180deg,
    transparent 50%,
    rgba(191, 220, 251, 0.2) 50%  /* Adjust for light theme */
  );
  background-size: 100% 10px;
}

.dark-theme-stripe {
  background-image: linear-gradient(
    180deg,
    transparent 50%,
    rgba(99, 99, 99, 0.251) 50%  /* Adjust for dark theme */
  );
  background-size: 100% 10px;
}

div.header-container{
  display: flex;
  width: 330px;
  height: 50px;
  margin: 0px auto;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 10px;
  margin-bottom: 6px;
  justify-content: center;
  border: 0px solid blue;
}

.logo{
  margin: 0px;
  padding: 0;
  width: fit-content;
  border: 0px dotted greenyellow;
}

div.switch{
  margin-top: 4px;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  transform: scale(120%);
  border: 0px solid green;
}

h1{
  margin: 20px 0 0 10px;
  font-size: 1.6em;
  font-weight: 600;
}

@media(min-width: 390px){
  #light .App{
    height: 100vh;
    background-color: #232323;
  }
  #dark > .App{
    background-color: rgb(241, 239, 235);;
  }

  div.errorMsg{
    width: 100%;
    text-align: left;
    border: 0px solid white;
    color: var(--clr-error);
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 20%
  }
}

@media(max-width: 390px){
  :root{
    --clr-white: #fff;
    /* --clr-key-text: var(--clr-black); */
    --enterKey-width: 50px;
    --keys-width: 8%;
    --keys-height: 70px;
    --keypad-width: 95%;
  }
  
  div.row {
    width: 300px;
    margin-left: calc((100% - 290px) / 2);
    display: flex;
    align-items: center;
    border: 0px solid blue;
  }
  div.row > div {
    font-weight: 600;
    font-size: 1.75em;
    line-height: 1.75em;
    width: 50px;
    height: 50px;
  }
  div.errorMsg{
    color: var(--clr-error);
    font-size: 1.1em;
    line-height: 1.2em;
    font-weight: 400;
    margin-top: -4px;
    margin-bottom: 10px;
  }
  div.deleteKey{
    max-width: fit-content;
  }
}
body {
  font-size: 1em;
  font-family: 'Clear Sans', 'Helvetica Neue', sans-serif;
  color: var(--clr-letter);
  text-align: center;
  border: 0px solid green;
}
h1{
  line-height: 1em;
  border: 0px solid red;
}
.modalTitle {
  margin-top:0px;
  font-size: 1.5em;
  line-height: 3em;
  text-align: center;
}
div.deleteKey{
  max-width: 40px;
}
span.stats {
  font-size: 2em;
}
.grid {
  flex: 1;
  width: 100vw;
  margin-top: -6px;
  justify-content: center;
  border: 0px solid blue;
}
/* rows */
.row {
  width: 340px;
  margin-left: calc((100% - 340px) / 2);
  display: flex;
  align-items: center;
  border: 0px solid blue;
}
.row > div {
  display: block;
  width: 60px;
  height: 60px;
  border: 1px solid var(--clr-med-grey);
  margin: 4px;
  text-align: center;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.5em;
  color: var(--clr-key-text);
}

.row > div.filled {
  color: var(--clr-tile-text);
  border: var(--clr-tile-highlight);
  animation: pulse 0.25s ease forwards;
}
.row > div.green {
  --background: var(--clr-correct);
  --border-color: var(--clr-correct);
  animation: flip 0.35s ease forwards;
}
.row > div.grey {
  --background: var(--clr-absent);
  --border-color: var(--clr-absent);
  animation: flip 0.6s ease forwards;
}
.row > div.yellow {
  --background: var(--clr-present);
  --border-color: var(--clr-present);
  animation: flip 0.5s ease forwards;
}
.row > div.winner:nth-child(1) {
  --background: var(--clr-correct);
  --border-color: var(--clr-correct);
  animation: flip 0.35s, success .25s ease-in-out .5s;
}
.row > div.winner:nth-child(2) {
  --background: var(--clr-correct);
  --border-color: var(--clr-correct);;
  animation: flip 0.35s, success .25s ease-in-out .75s;
}
.row > div.winner:nth-child(3) {
  --background: var(--clr-correct);
  --border-color: var(--clr-correct);
  animation: flip 0.35s, success .25s ease-in-out 1s;
}
.row > div.winner:nth-child(4) {
  --background: var(--clr-correct);
  --border-color: var(--clr-correct);
  animation: flip 0.35s, success .25s ease-in-out 1.25s;
}
.row > div.winner:nth-child(5) {
  --background: var(--clr-correct);
  --border-color: var(--clr-correct);
  animation: flip 0.35s, success .25s ease-in-out 1.5s;
}
div.winner{
  color: rgb(241, 239, 235);;
  background: var(--clr-correct);
}
.keypad {
  text-align: center;
  width: var(--keypad-width);
  margin: 2px auto;
  /* margin-left: calc((100% - var(--keypad-width)) / 2); */
  /* margin-left: -5.5%; */
  cursor: pointer;
}
.keypad > div {
  margin: 3px;
  font-size: .8em;
  font-weight: 600;
  color: var(--clr-key-text);
  width: var(--keys-width);
  height: var(--keys-height);
  background: var(--clr-key-bkgnd);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 6px;
  line-height: 50px;
  --webkit-user-select: none;
  user-select: none;
}
.keypad > div.green {
  background: var(--clr-correct);
  color: #fff;
  transition: all 0.3s ease-in;
}
.keypad > div.yellow {
  background: var(--clr-present);
  color: #fff;
  transition: all 0.3s ease-in;
}
.keypad > div.grey {
  background: var(--clr-absent);
  color: #fff;
  transition: all 0.3s ease-in;
}
.keypad > div.enterKey{
  width: var(--enterKey-width);
  font-size: .85em;
}
.keypad > div.deleteKey{
  font-size: .85em;
  width: 70px;
}
.modal {
  background: rgba(255,255,255,0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0px solid red;
}
.modal div {
  width: 350px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin: auto auto;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  border: 0px solid red;
}
.modal .solution {
  color: var(--clr-error);
  font-weight: bold;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
table {
  width: 80%;
  margin: 10px;
  margin-left: 10%;
  border: 0px solid red;
}
td {
  margin: 0;
  padding: 0;
  width: 50%;
  text-transform: capitalize;
}
.statsContainer{
  text-align: center;
}
.statsItem{
  margin-top: -20px;
}
.statsNumber {
  padding:  0;
  margin: 0;
  font-size: 2.5em;
}
span.statsLabel {
  margin-top: -20px;
  color: var(--clr-med-grey);
  font-size: .8em;
}

.btnNewGame {
  display: relative;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  margin-bottom: 30px;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--clr-correct);
  color: var(--clr-white);
  box-shadow: 0px 2px 6px #99a497;
  font-size: 1.25em;
  cursor: pointer;
  border: none;
}
  /* ---TOGGLE STYLE--- */
  .reactSwitch{
    margin-top: 20px;
    border: 0px solid red;
  }
  .fa-sun {
    font-size: 1.25rem;
    margin: 4px 0 2px 4px;
    text-align:center;
    color: #f8d538;
  }
  #light .fa-sun {
    font-size: 1.25rem;
    margin: 4px 0 2px 4px;
    text-align:center;
    color: #f8d538;
  }
  #light .fa-moon {
    font-size: 1.5rem;
    margin: 2px 5px auto;
    text-align:center;
    color: rgb(15, 238, 238);
  }
  #light .checkbox {
    opacity: 0;
    position: absolute;
  }
  #light .checkbox-label {
    margin-top: 8px;
    color: var(--clr-white);
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #light .checkbox-label .ball {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  #light .react-switch-handle:hover,
  #light .react-switch-handle:active{
    border: 2px solid rgb(255, 179, 0)!important;
  }
  #dark .react-switch-handle:hover,
  #dark .react-switch-handle:active{
    border: 2px solid rgb(15, 238, 238)!important;
  }
  #light .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
  }
  #dark .fa-moon {
    font-size: 1.5rem;
    margin: 2px 5px auto;
    text-align:center;
    color: rgb(15, 238, 238);
  }
  #dark .fa-sun {
    font-size: 1.25rem;
    margin: 4px 0 2px 4px;
    text-align:center;
    color: #f8d538;
  }
  
  

    /* ---END TOGGLE STYLES --- */
    .App {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: rgb(241, 239, 235);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .App::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .App {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
    
    /* Add media query for desktop 
    @media (min-width: 768px) {
      .App {
        margin-top:'-200px';
      }
    }*/

    div.errorMsg{
      margin:0;
      padding: 0;
      text-align: center;
      border: 0px solid white;
    }
    #light > * div.filled{
      color: var(--clr-dk-grey);
    }
    #dark > * div.filled{
      color: var(--clr-white);
    }
    #light .App,
    #dark .App,
    #light div.container,
    #dark div.container
    {
      width: 100vw;
      border: 0px solid red;
    }
@keyframes success {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -30px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #fff;
    border-color: #333;
  }
  45% {
    transform: rotateX(90deg);
    background: white;
    border-color: #333;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border-color: var(--border-color);
  }
  100% {
    transform: rotateX(0deg);
    background: var(--background);
    border-color: var(--border-color);
    color: #fff;
  }
}

@keyframes pulse {
  0% { 
    transform: scale(1);
  }
  50% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% { 
    transform: scale(1);
    border-color: #ddd;
  }
  50% { 
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    border-color: #333;
  }
}

